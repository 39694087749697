<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('nav.settings')"
      :sub-title="$t('common.settings_subtitle')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="no-gutters px-2">
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="accountDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <personal-information-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('personal_information.title') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="adminsDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <admins-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.admins') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="operationLogDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <operation-log-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.operation_log') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="settingsDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <settings-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.advanced_settings') }}
            </h4>
          </b-card>
        </b-col>

        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="sectorsDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <sectors-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.sectors') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="fieldsDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <fields-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.fields') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="servicesDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <services-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('settings.services') }}
            </h4>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      // permissions: loginData.permissions,
    };
  },
  computed: {},
  mounted() {
    this.IsCanPermission();
  },
  methods: {
    IsCanPermission(permission) {
      const loginData = this.$cookies.get('super-login-data', { parseJSON: true });
      return loginData.permissions.includes(permission);
    },
    accountDetails() {
      this.$router.push({
        name: 'account-information',
        params: { lang: this.$i18n.locale },
      });
    },
    adminsDetails() {
      this.$router.push({
        name: 'administrators',
        params: { lang: this.$i18n.locale },
      });
    },
    operationLogDetails() {
      this.$router.push({
        name: 'operation-log',
        params: { lang: this.$i18n.locale },
      });
    },
    settingsDetails() {
      this.$router.push({
        name: 'advanced',
        params: { lang: this.$i18n.locale },
      });
    },
    sectorsDetails() {
      this.$router.push({
        name: 'sectors',
        params: { lang: this.$i18n.locale },
      });
    },
    fieldsDetails() {
      this.$router.push({
        name: 'fields',
        params: { lang: this.$i18n.locale },
      });
    },
    servicesDetails() {
      this.$router.push({
        name: 'services',
        params: { lang: this.$i18n.locale },
      });
    },
  },
};
</script>
